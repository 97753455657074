.login-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #ffffff;
  z-index: 1000;
  animation: slideIn 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  display: flex;
  align-items: stretch;
  padding: 0;
}

@keyframes slideIn {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.login-container.slide-out {
  animation: slideOut 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

@keyframes slideOut {
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(20px);
    opacity: 0;
  }
}

.login-content {
  display: flex;
  width: 100%;
  height: 100%;
  margin: 0;
  max-width: none;
}

.login-left {
  width: 500px;
  flex: none;
  padding: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: white;
  margin: 0;
}

.login-right {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, #34A853 0%, #2d9147 100%);
  position: relative;
  overflow: hidden;
  margin: 0;
}

.login-right::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: radial-gradient(
    circle at 1px 1px,
    rgba(255, 255, 255, 0.15) 1px,
    transparent 0
  );
  background-size: 24px 24px;
  opacity: 0.3;
  animation: moveBackground 30s linear infinite;
}

@keyframes moveBackground {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 200px 200px;
  }
}

.login-right::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(
    circle at 70% 50%,
    rgba(52, 168, 83, 0.1) 0%,
    rgba(45, 145, 71, 0.4) 100%
  );
}

.login-image {
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url('../assets/login-bg.png');
  background-size: cover;
  background-position: center;
  opacity: 0.1;
}

.login-right-content {
  position: relative;
  z-index: 2;
  color: white;
  text-align: center;
  padding: 2rem;
  max-width: 500px;
  margin: 0 auto;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.login-right-content h2 {
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
}

.login-right-content p {
  font-size: 1.1rem;
  line-height: 1.6;
  opacity: 0.9;
}

.login-header {
  margin-bottom: 3.5rem;
  padding-top: 5rem;
  position: relative;
  z-index: 1;
}

.login-logo {
  height: 48px;
  margin-bottom: 2rem;
}

.login-header h1 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.login-header p {
  font-size: 1.1rem;
}

.login-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 1rem;
  width: 100%;
  animation: slideUpFade 0.5s cubic-bezier(0.4, 0, 0.2, 1) both;
  animation-delay: calc(var(--index) * 0.1s);
}

.form-group label {
  color: #475569;
  font-weight: 500;
  font-size: 0.875rem;
  margin-left: 0.25rem;
}

.input-with-icon {
  position: relative;
  width: 100%;
}

.input-icon {
  position: absolute;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  color: #94a3b8;
  pointer-events: none;
}

.form-group input {
  width: 100%;
  padding: 0.875rem 1rem;
  padding-left: 2.75rem;
  border: 2px solid #e2e8f0;
  border-radius: 10px;
  font-size: 1rem;
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  height: 3.5rem;
  box-sizing: border-box;
  background: #f8fafc;
}

.form-group input:hover {
  border-color: #cbd5e1;
}

.form-group input:focus {
  background: #ffffff;
  border-color: #34A853;
  box-shadow: 0 0 0 4px rgba(52, 168, 83, 0.1);
}

.form-options {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.5rem 0 1.5rem;
}

.checkbox-container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  white-space: nowrap;
  position: relative;
}

.checkbox-container input[type="checkbox"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: relative;
  display: inline-block;
  width: 18px;
  height: 18px;
  background-color: white;
  border: 2px solid #e2e8f0;
  border-radius: 4px;
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

/* Checkmark/indicator */
.checkbox-container input[type="checkbox"]:checked ~ .checkmark {
  background-color: #34A853;
  border-color: #34A853;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
  left: 5px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.checkbox-container input[type="checkbox"]:checked ~ .checkmark:after {
  display: block;
}

/* Focus state */
.checkbox-container input[type="checkbox"]:focus ~ .checkmark {
  box-shadow: 0 0 0 3px rgba(52, 168, 83, 0.1);
}

/* Hover state */
.checkbox-container:hover .checkmark {
  transform: scale(1.05);
}

.checkbox-text {
  color: #64748b;
  font-size: 0.875rem;
}

.forgot-password {
  color: #34A853;
  text-decoration: none;
  font-size: 0.875rem;
  font-weight: 500;
}

.forgot-password:hover {
  text-decoration: underline;
}

.login-btn {
  height: 3.5rem;
  font-size: 1rem;
  font-weight: 500;
  margin-top: 1rem;
  border-radius: 10px;
  width: 100%;
  padding: 0.75rem;
  background: linear-gradient(135deg, #34A853 0%, #2d9147 100%);
  color: white;
  border: none;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
}

.login-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 16px rgba(52, 168, 83, 0.2);
}

.login-btn:active {
  transform: translateY(0);
}

.login-btn.loading {
  background-color: #7fc091;
  cursor: not-allowed;
}

.spinner {
  width: 20px;
  height: 20px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: white;
  animation: spin 0.8s linear infinite;
  margin: 0 auto;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.social-divider {
  display: flex;
  align-items: center;
  text-align: center;
  color: #94a3b8;
  font-size: 0.875rem;
  margin: 2rem 0;
}

.social-divider::before,
.social-divider::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #e2e8f0;
}

.social-divider span {
  padding: 0 1rem;
}

.social-login {
  display: flex;
  flex-direction: column;
}

.social-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
  width: 100%;
  padding: 0.75rem;
  border: 2px solid #e2e8f0;
  border-radius: 10px;
  background: #ffffff;
  color: #0f172a;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  height: 3.5rem;
}

.social-btn:hover {
  border-color: #cbd5e1;
  transform: translateY(-2px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.05);
}

.social-icon {
  width: 20px;
  height: 20px;
}

.register-link {
  text-align: center;
  margin-top: 2.5rem;
}

.register-link a {
  color: #34A853;
  text-decoration: none;
  font-size: 0.875rem;
  font-weight: 500;
}

.register-link a:hover {
  text-decoration: underline;
}

.social-btn.apple {
  display: none;
}

.back-button {
  position: absolute;
  top: 2rem;
  left: 2rem;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  border: none;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.08);
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 2;
}

.back-button:hover {
  transform: translateX(-4px);
  box-shadow: 0 12px 20px rgba(0, 0, 0, 0.12);
}

.back-icon {
  width: 20px;
  height: 20px;
  object-fit: contain;
  transition: transform 0.2s;
}

.back-button:hover .back-icon {
  transform: scale(1.1);
}

@keyframes slideUpFade {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 1024px) {
  .login-left {
    width: 100%;
    max-width: none;
    padding: 3rem 2rem;
    background: linear-gradient(135deg, #ffffff 0%, #f8fafc 100%);
  }
}

@media (max-width: 480px) {
  .login-left {
    padding: 2rem 1.5rem;
  }
  
  .login-header {
    padding-top: 4rem;
  }
  
  .login-logo {
    height: 40px;
  }
  
  .login-header h1 {
    font-size: 1.75rem;
  }
  
  .form-group input,
  .login-btn,
  .social-btn {
    height: 3.25rem;
  }
  
  .back-button {
    top: 1rem;
    left: 1rem;
    width: 40px;
    height: 40px;
  }
  
  .login-form {
    gap: 1.25rem;
  }
  
  .form-options {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }
} 