.pre-register-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(8px);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  overflow: hidden;
}

.pre-register-content {
  width: 100%;
  height: 100vh;
  max-width: none;
  max-height: none;
  background: #fff;
  border-radius: 0;
  box-shadow: none;
  display: grid;
  grid-template-columns: 1fr 1fr;
  overflow: hidden;
  position: relative;
  animation: slideIn 0.5s ease-out;
}

.pre-register-left {
  padding: 3rem 4rem;
  overflow-y: auto;
  position: relative;
  background: #fff;
}

.pre-register-right {
  position: relative;
  background: linear-gradient(135deg, #34A853, #2D9348);
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 4rem;
  overflow: hidden;
}

.pre-register-right::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url('../assets/login-bg.png') center/cover;
  opacity: 0.1;
}

.pre-register-right-content {
  position: relative;
  z-index: 1;
  text-align: left;
}

.pre-register-right-content h2 {
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  font-weight: 700;
  line-height: 1.2;
}

.pre-register-right-content p {
  font-size: 1.1rem;
  line-height: 1.6;
  opacity: 0.9;
}

.pre-register-header {
  text-align: center;
  margin-bottom: 2.5rem;
}

.pre-register-logo {
  width: 160px;
  margin-bottom: 1.5rem;
  filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.1));
  transition: transform 0.3s ease;
}

.pre-register-logo:hover {
  transform: scale(1.05);
}

.pre-register-header h1 {
  font-size: 2rem;
  color: #1a1a1a;
  margin-bottom: 0.5rem;
  font-weight: 700;
}

.pre-register-header p {
  color: #666;
  font-size: 1rem;
  line-height: 1.5;
  max-width: 400px;
  margin: 0 auto;
}

.pre-register-form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.form-group {
  opacity: 0;
  animation: fadeInUp 0.5s ease-out forwards;
  animation-delay: calc(var(--index) * 0.1s);
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  color: #2c2c2c;
  font-weight: 600;
  font-size: 0.9rem;
}

.input-with-icon {
  position: relative;
}

.input-icon {
  position: absolute;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  color: #34A853;
  opacity: 0.8;
}

.input-with-icon input,
.input-with-icon select {
  width: 100%;
  padding: 0.9rem 1rem 0.9rem 2.8rem;
  border: 2px solid #e0e0e0;
  border-radius: 12px;
  font-size: 1rem;
  transition: all 0.3s ease;
  background: white;
  color: #333;
  height: 48px;
}

.input-with-icon input:focus,
.input-with-icon select:focus {
  border-color: #34A853;
  box-shadow: 0 0 0 3px rgba(52, 168, 83, 0.1);
  outline: none;
}

.radio-group {
  display: flex;
  gap: 1rem;
  margin-top: 0.5rem;
}

.radio-label {
  flex: 1;
  position: relative;
  cursor: pointer;
  border-radius: 12px;
  transition: all 0.3s ease;
  background: #f8f9fa;
  border: 2px solid #e0e0e0;
  padding: 1rem;
}

.radio-label:hover {
  border-color: #34A853;
  background: #f0f9f2;
}

.radio-content {
  display: flex;
  align-items: center;
  gap: 0.8rem;
}

.radio-icon {
  width: 24px;
  height: 24px;
  color: #34A853;
}

.radio-text {
  font-size: 0.95rem;
  font-weight: 500;
  color: #333;
}

.radio-label input[type="radio"] {
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  margin: 0;
  cursor: pointer;
}

.radio-label input[type="radio"]:checked + .radio-content {
  color: #34A853;
}

.radio-label:has(input[type="radio"]:checked) {
  border-color: #34A853;
  background: #f0f9f2;
}

.radio-label.disabled {
  opacity: 0.5;
  cursor: not-allowed;
  background: #f0f0f0;
}

.radio-label.disabled:hover {
  border-color: #e0e0e0;
  background: #f0f0f0;
  transform: none;
}

.radio-label.disabled input[type="radio"] {
  cursor: not-allowed;
}

.radio-label.disabled .radio-content {
  color: #999;
}

.radio-label.disabled .radio-icon {
  opacity: 0.5;
}

.submit-button {
  width: 100%;
  padding: 1rem;
  background: #34A853;
  color: white;
  border: none;
  border-radius: 12px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 1rem;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.submit-button:hover {
  background: #2d9348;
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(52, 168, 83, 0.2);
}

.back-button {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  background: rgba(255, 255, 255, 0.1);
  border: none;
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  z-index: 10;
}

.back-button:hover {
  background: rgba(255, 255, 255, 0.2);
  transform: scale(1.1);
}

.back-icon {
  width: 20px;
  height: 20px;
  opacity: 0.8;
}

.error-message {
  color: #dc3545;
  background: #fff5f5;
  padding: 1rem;
  border-radius: 12px;
  margin-top: 1rem;
  font-size: 0.9rem;
  border: 1px solid #ffebeb;
}

.success-message {
  text-align: center;
  padding: 2rem;
  background: #f0f9f2;
  border-radius: 12px;
  margin-top: 2rem;
}

.success-message h3 {
  color: #34A853;
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

@media (max-width: 1024px) {
  .pre-register-content {
    grid-template-columns: 1fr;
    height: 100vh;
  }

  .pre-register-right {
    display: none;
  }

  .pre-register-left {
    padding: 2rem 3rem;
  }
}

@media (max-width: 480px) {
  .pre-register-container {
    padding: 0;
  }

  .pre-register-content {
    border-radius: 0;
  }

  .pre-register-left {
    padding: 2rem;
  }

  .radio-group {
    flex-direction: column;
  }

  .radio-label {
    width: 100%;
  }
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Accessibility improvements */
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

.typing-title {
  position: relative;
  display: inline-block;
}

.cursor {
  display: inline-block;
  margin-left: 2px;
  opacity: 1;
  animation: blink 1s infinite;
  font-weight: 100;
}

@keyframes blink {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

.privacy-policy-group {
  margin-bottom: 20px;
}

.checkbox-label {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  cursor: pointer;
  user-select: none;
}

.checkbox-label input[type="checkbox"] {
  width: 18px;
  height: 18px;
  margin-top: 2px;
  cursor: pointer;
  accent-color: var(--accent);
}

.checkbox-text {
  font-size: 14px;
  line-height: 1.4;
  color: var(--text-secondary);
}

.privacy-policy-group .error-message {
  margin-top: 8px;
  margin-left: 28px;
  font-size: 13px;
  color: #dc3545;
}

/* Privacy Policy Modal Styles */
.privacy-policy-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(5px);
  animation: fadeIn 0.2s ease-out;
}

.privacy-policy-modal {
  background: white;
  border-radius: 12px;
  padding: 24px;
  width: 90%;
  max-width: 600px;
  max-height: 80vh;
  overflow-y: auto;
  position: relative;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  animation: slideIn 0.3s ease-out;
}

.privacy-policy-modal h2 {
  margin: 0 0 20px 0;
  color: var(--text-primary);
  font-size: 1.5rem;
}

.privacy-policy-content {
  margin-bottom: 20px;
}

.privacy-policy-content pre {
  white-space: pre-wrap;
  font-family: inherit;
  line-height: 1.6;
  color: var(--text-secondary);
  margin: 0;
}

.privacy-policy-modal .close-button {
  width: 100%;
  padding: 12px;
  background: var(--accent);
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.2s ease;
}

.privacy-policy-modal .close-button:hover {
  background: var(--accent-dark);
}

.privacy-policy-link {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  color: var(--accent);
  cursor: pointer;
  font-size: inherit;
  text-decoration: underline;
}

.privacy-policy-link:hover {
  color: var(--accent-dark);
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Mobile responsiveness for privacy policy modal */
@media (max-width: 768px) {
  .privacy-policy-modal {
    width: 95%;
    padding: 20px;
    max-height: 85vh;
  }

  .privacy-policy-modal h2 {
    font-size: 1.3rem;
  }

  .privacy-policy-content {
    font-size: 0.9rem;
  }
} 
